import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useRef
} from "react";
import Link from "gatsby-link";
import styled, { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import AOS from "aos";

import Header from "../Header";
import Footer from "../Footer";

import GlobalContext from "../../context/GlobalContext";

import GlobalStyle from "../../utils/globalStyle";

import imgFavicon from "../../assets/favicon.png";
import { navigate } from "gatsby";
import { getProperty } from "../../utils/helperFn";
import "../../assets/fonts/fontawesome-5/webfonts/fa-brands-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-regular-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-solid-900.ttf";
import "../../assets/fonts/typography-font/Circular-Std-Book.ttf";
import "../../assets/fonts/typography-font/CircularStd-Black.ttf";
import "../../assets/fonts/typography-font/CircularStd-BlackItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Bold.ttf";
import "../../assets/fonts/typography-font/CircularStd-BoldItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Book.ttf";
import "../../assets/fonts/typography-font/CircularStd-BookItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Medium.ttf";
import "../../assets/fonts/typography-font/CircularStd-MediumItalic.ttf";

import "../../assets/fonts/icon-font/fonts/avasta.ttf";
import "../../assets/fonts/icon-font/css/style.css";

import "./bootstrap-custom.scss";
import "./accordion.scss";
import "../../../node_modules/aos/dist/aos.css";

import "../../assets/fonts/icon-font/css/style.css";
import "../../assets/fonts/typography-font/typo.css";
import "../../assets/fonts/fontawesome-5/css/all.css";
import "../../assets/fonts/icomoon/style.css";

import { get, merge } from "lodash";
import CookieConsent, { Cookies } from "react-cookie-consent";

import CookieBot from 'react-cookiebot'

// the full theme object
import { theme as baseTheme } from "../../utils";

const domainGroupId = 'ecebf7c2-ecb8-4f9f-8fe6-65f1a6339773';

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 0.5s;
  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underine !important;
  color: #3b3b3b !important;
  &:hover {
    text-decoration: none !important;
  }  
`;

// options for different color modes
const modes = { light: "light", dark: "dark" };

// merge the color mode with the base theme
// to create a new theme object
const getTheme = (mode) =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
  });

const Layout = ({ children, pageContext }) => {
  const gContext = useContext(GlobalContext);

  const [visibleLoader, setVisibleLoader] = useState(true);

  useLayoutEffect(() => {
    if (window.innerWidth > 1200) {
      AOS.init();
      document.getElementsByClassName('site-wrapper')[0].classList.add('desktop-device')
    } else {
      document.getElementsByClassName('site-wrapper')[0].classList.add('mobile-device')
    }
    setVisibleLoader(false);
  }, []);

  // Navbar style based on scroll
  const eleRef = useRef();

  useEffect(() => {
    window.addEventListener(
      "popstate",
      function (event) {
        // The popstate event is fired each time when the current history entry changes.

        gContext.closeOffCanvas();
      },
      false
    );
    window.addEventListener(
      "pushState",
      function (event) {
        // The pushstate event is fired each time when the current history entry changes.
        gContext.closeOffCanvas();
      },
      false
    );
  }, [gContext]);

  return (
    <>
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <GlobalStyle />
        <Helmet>
          <link rel="icon" type="image/png" href={imgFavicon} />
        </Helmet>
        <CookieConsent
          location="bottom"
          buttonText="Accept All"
          declineButtonText="Decline"
          containerClasses="text-center text-md-left cookieConsentContainer"
          buttonWrapperClasses="cookieConsentButtons"
          cookieName="netomniaCookieConsent"
          style={{
            fontSize: "16px",
            color: "#ffffff",
            paddingLeft: "20px", paddingRight: "20px",
            background: "linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)"
          }}
          buttonStyle={{
            textAlign: "center",
            background: "#ffffff",
            color: "#a258ca",
            fontSize: "16px",
            paddingLeft: "30px", paddingRight: "30px"
          }}
          enableDeclineButton
          declineButtonStyle={{
            textAlign: "center",
            background: "transparent",
            color: "#ffffff",
            border: "1px solid #ffffff",
            fontSize: "16px",
            margin: 0,
            paddingLeft: "30px", paddingRight: "30px"
          }}
          expires={150}
          onAccept={() => {
            Cookies.set('gatsby-gdpr-google-analytics', true)
            Cookies.set('gatsby-gdpr-google-tagmanager', true)
            Cookies.set('gatsby-gdpr-facebook-pixel', true)
          }}
        >
          We use <StyledLink to="/legal/privacy-and-cookie-policy">cookies</StyledLink> to give you the best browsing experience, to analyze
          our website usage and to market our products and services to you.<br></br>
          In case you do not wish to give your consent, you may nonetheless use
          this website without restriction. You can revoke your consent at any time.
        </CookieConsent>

        <Loader id="loading" className={visibleLoader ? "" : "inActive"}>
          <div className="load-circle">
            <span className="one"></span>
          </div>
        </Loader>
        <div className="site-wrapper overflow-hidden" ref={eleRef}>
          <Header isDark={gContext.headerDark} />
          {children}
          <Footer isDark={gContext.footerDark} />
        </div>
        {typeof window !== 'undefined' && <CookieBot domainGroupId={domainGroupId} />}
      </ThemeProvider>
    </>
  );
};

export default Layout;
